@import '@styles/typography';

.root,
.hello {
  background: var(--chakra-colors-gray-1) !important;
  border: 1px solid var(--chakra-colors-gray-7) !important;;
  border-radius: 12px;
  font-size: 16px;
  color: var(--chakra-colors-gray-4) !important;
  max-width: 250px !important;
  padding: 12px;

  :global(.tippy-content) {
    padding: 0 !important;
  }
}

.trigger {
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
}

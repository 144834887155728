@import 'src/styles/media';

.root.root {
  background-color: var(--chakra-colors-brand-black);
  @include tablet-landscape {
    padding: 0;
  }
}

.wrapper.wrapper {
  max-width: 1440px;
  @include tablet-landscape {
    background-color: var(--chakra-colors-brand-black);
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
    padding: 18px 20px;

    margin: auto;
    width: 100%;
  }
}

@import 'src/styles/mixins';
@import 'src/styles/media';

.root {
  grid-template-columns: 1fr;
  grid-column-gap: 84px;
  grid-row-gap: 24px;
  display: grid;
  padding: 34px 20px 20px;
  background: var(--chakra-colors-brand-black);
  min-height: 258px;

  max-width: 1440px;
  margin: auto;
  width: 100%;
  padding-bottom: 94px;

  @include desktop {
    grid-template-columns: 340px 1fr;
    grid-gap: 84px;
  }
}

.socialLink {
  @include centralize;
  padding: 8px;
  color: var(--chakra-colors-brand-primary);

  //border: 1px solid var(--chakra-colors-brand-primary);
  //border-radius: 3px;
}

.socialInfo {
  padding: 8px 14px;
  color: var(--chakra-colors-brand-secondary);
  background-color: var(--chakra-colors-whiteOpacity-6);
}

.topicTitle {
  font-size: 14px;
  line-height: 20px;
  color: var(--chakra-colors-brand-primary);
}

.nav {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;

  > div {
    flex: 1;
    max-width: 220px;
  }
}

.listItem {
  margin: 16px 0;
  align-items: center;
  display: flex;
  white-space: nowrap;
  color: var(--chakra-colors-brand-secondary);

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }

  > a {
    display: flex;
    align-items: center;
    color: var(--chakra-colors-brand-secondary);
    font-family: Roboto, sans-serif;

    svg:first-child {
      margin-right: 6px;
    }
  }
}

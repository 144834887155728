.custom-wallet-button.custom-wallet-button {
  background: var(--chakra-colors-brand-black);
  width: 100%;
  color: var(--chakra-colors-neutral-200);
  border: 1px solid var(--chakra-colors-neutral-200);
  font-family: Roboto, sans-serif;

  &:hover {
    filter: brightness(120%);
  }
}
.connectBtn {
  padding: 8px;
  font-size: 14px;
}

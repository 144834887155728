@use 'typography';
@use 'reset';
@use 'mixins';
@use 'colors';
@use 'media';
@import 'tippy.js/animations/shift-away.css';
@import 'react-loading-skeleton/dist/skeleton.css';

:root {
  --app-header-height: 100px;
  --app-footer-height: 320px;
  --font-primary: 'Alegreya Variable', sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
}

html {
  background-color: #202020;
  color: var(--brand-black);
  font-family: var(--font-primary), sans-serif;
}

html,
body {
  @include mixins.custom-scrollbar-vertical;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  max-width: 100vw;
  width: 100%;
  min-height: 100dvh;
  font-family: 'Alegreya Variable', serif;
}

p {
  color: var(--brand-black);
}

a {
  color: initial;
  text-decoration: none;
}

.Toastify__toast.Toastify__toast {
  padding: 0;
}

.Toastify__toast-body.Toastify__toast-body {
  padding: 0;
}

#synapse-loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100dvh;
  z-index: 9999999;
}

.dropdown {
  z-index: 110;

  .arrow {
    border-bottom-color: inherit;
    border-left-color: transparent;
    border-right-color: transparent;
    border-style: solid;
    border-top-color: transparent;
    border-width: 0 6px 6px;
    height: 0;
    width: 0;
  }

  &[data-popper-placement^='bottom'] > .arrow {
    top: -6px;
  }

  &[data-popper-placement^='top'] > .arrow {
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;

    border-top-color: inherit;
    border-width: 6px 6px 0;
    bottom: -6px;
  }

  &[data-popper-placement^='left'] > .arrow {
    border-bottom-color: transparent;
    border-left-color: inherit;
    border-right-color: transparent;

    border-top-color: transparent;
    border-width: 6px 0 6px 6px;
    right: -6px;
  }

  &[data-popper-placement^='right'] > .arrow {
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: inherit;

    border-top-color: transparent;
    border-width: 6px 6px 6px 0;
    left: -6px;
  }
}

.mobileDropdown {
  height: 100dvh;
  z-index: 100000;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  background-color: var(--chakra-colors-brand-black);
  border-radius: 12px 12px 0 0;

  > div {
    width: 100% !important;
  }
}

.icon {
  height: 16px;
  width: 16px;
}

.link {
  font-size: 16px;
  color: var(--chakra-colors-brand-secondary);
  cursor: pointer;
  transition: all 0.2s ease;
  padding-block: 8px;
  padding-inline: 8px;
  width: 100%;
  border-radius: 8px;

  &.active {
    color: var(--chakra-colors-brand-primary);
  }

  &:hover {
    color: var(--chakra-colors-brand-primary);
    //color: var(--chakra-colors-orange);
    //background-color: var(--chakra-colors-neutral-700);
  }
}

.mobileLink {
  padding: 4px 0;
}

.right > div {
  display: flex;
  flex-direction: column;
}

:root {
  // brand
  --brand-black: #121212;
  --brand-white: #F5F2EB;
  --brand-orange: #fdd7af;

  // common
  --black: #000;
  --white: #fff;

  // gray
  --gray-1: #131313;
  --gray-2: #1a1a1a;

  // neutral
  --neutral-100: #e2e2e2;

  // opacity
  --white-opacity-04: rgb(255 255 255 / 4%);
  --white-opacity-06: rgb(255 255 255 / 6%);
  --white-opacity-10: rgb(255 255 255 / 10%);
  --white-opacity-50: rgb(255 255 255 / 50%);
}

@import 'src/styles/mixins';
@import 'src/styles/media';

.root {
  display: flex;
  flex-direction: column;
  background-color: #202020;
  background-size: contain;
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  min-height: calc(100dvh - var(--app-header-height) - var(--app-footer-height));
  max-width: 1440px;
  margin: auto;
  width: 100%;
  padding-inline: 16px;
  z-index: 0;
  margin-bottom: 64px;

  @include desktop {
    padding-inline: unset;
  }
}

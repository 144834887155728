.root {
  //box-shadow: 0 0 4px 4px var(--chakra-colors-brand-black);
  z-index: 1;
}

.main {
  transform: translateY(-8px);

  &::before {
    content: "";
    position: absolute;
    width: 64px;
    height: 64px;
    border-radius: 24px;
    background: #121212;
    z-index: -1;
    top: -24px;
    border-top: 1px solid var(--chakra-colors-brand-primary);
  }
}

.active {
  color: var(--chakra-colors-brand-primary);
}
